import { useQuery } from "@tanstack/react-query";
import IssuerClient from "../clients/IssuerClient";
import { useApi } from "./useApi";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuer = (id?: string, options = {}) => {
  const api = useApi(IssuerClient);

  return useQuery(["issuers", id], () => api.getIssuer(id), {
    enabled: !!id,
    ...options,
  });
};

export const useIssuers = (
  params: Partial<{
    search: string;
    sortBy: string;
    page: number;
    perPage: number;
  }>,
  options = {}
) => {
  const { search, sortBy, page = 1, perPage = 25 } = params ?? {};
  const api = useApi(IssuerClient);

  return useQuery(
    ["issuers", { search, page, perPage, sortBy }],
    () => api.listIssuers({ search, page, perPage, sortBy }),
    options
  );
};
