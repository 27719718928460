import React from "react";
import {
  Skeleton,
  HStack,
  Box,
  VStack,
  Card,
  CardBody,
  Flex,
} from "@chakra-ui/react";

export const InvestmentHeaderSkeleton: React.FC = () => {
  return (
    <Card>
      <CardBody>
        <Flex justify="space-between" align="center">
          <Flex align="center" gap={2}>
            <Skeleton boxSize="64px" borderRadius="md" />
            <Box>
              <Flex align="center" gap={1} mb={0}>
                <Skeleton height="20px" width="150px" />
                <Skeleton height="20px" width="20px" />
              </Flex>
              <Flex align="center" gap={3} mt={2}>
                <Skeleton height="28px" width="200px" />
                <Skeleton height="20px" width="80px" borderRadius="md" />
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export const InquiryRowSkeleton: React.FC = () => {
  return (
    <Flex align="center" justify="space-between" w="full" py={3}>
      <Flex align="center" gap={3}>
        <Skeleton boxSize="40px" borderRadius="md" />
        <VStack align="start" spacing={1}>
          <Skeleton height="16px" width="200px" />
          <Skeleton height="14px" width="150px" />
        </VStack>
      </Flex>
      <Skeleton height="32px" width="100px" borderRadius="md" />
    </Flex>
  );
};

export const PaymentRowSkeleton: React.FC = () => {
  return (
    <VStack align="stretch" flex={1} spacing={4}>
      <HStack align="center" spacing={2} mb={2}>
        <Skeleton boxSize="40px" borderRadius="md" />
        <Skeleton height="20px" width="100px" />
      </HStack>
      <VStack align="flex-start" spacing={2}>
        <Skeleton height="16px" width="200px" />
        <Skeleton height="14px" width="180px" />
      </VStack>
      <HStack spacing={4}>
        <Skeleton height="32px" width="150px" borderRadius="md" />
        <Skeleton height="32px" width="150px" borderRadius="md" />
      </HStack>
    </VStack>
  );
}; 