import React from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Input } from "@equidefi/ui";
import { useUpdateUserById } from "@equidefi/portals/hooks/useUsers";

interface EditBasicInfoModalProps {
  modal: {
    isOpen: boolean;
    onClose: () => void;
  };
  user: User;
  onSuccess: () => void;
}

export const EditBasicInfoModal = ({ modal, user, onSuccess }: EditBasicInfoModalProps) => {
  const toast = useToast();
  const updateUser = useUpdateUserById(user?.id);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
    }
  });

  const onSubmit = async (data) => {
    try {
      await updateUser.mutateAsync(data);
      toast({
        status: "success",
        description: "Basic info updated successfully",
      });
      onSuccess();
      modal.onClose();
    } catch (error) {
      toast({
        status: "error",
        description: error.response?.data?.errors?.join(". ") || "Something went wrong",
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Edit Basic Info</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                label="First Name"
                error={errors.first_name?.message}
                {...register("first_name", { 
                  required: "First name is required" 
                })}
              />
              <Input
                label="Last Name"
                error={errors.last_name?.message}
                {...register("last_name", { 
                  required: "Last name is required" 
                })}
              />
              <Input
                label="Email"
                error={errors.email?.message}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Must be a valid email"
                  }
                })}
              />
            </ModalBody>
            <ModalFooter as={ButtonGroup}>
              <Button type="submit" isLoading={updateUser.isLoading}>Save</Button>
              <Button variant="outline" onClick={modal.onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}; 