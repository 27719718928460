import { createRoot } from "react-dom/client";

import { initialize as initializeFullStory } from "@equidefi/portals/helpers/fullstory";
import { buildLDProvider } from "@equidefi/portals/providers/LaunchDarkly";

import App from "./App";
import { CONFIG } from "./constants/config";
import { sentryStart } from "./helpers/sentry";
import
  {
    IssuerApiProvider,
    IssuerColorMode,
    IssuerQueryProvider,
    IssuerReduxProvider
  } from "./providers";

// css
import AuthProvider from "@equidefi/portals/context/AuthProvider";
import "./style/app.css";
import "./style/custom.css";
import "./style/fonts.css";
import "./style/icons.css";

sentryStart();
initializeFullStory(CONFIG.FULLSTORY_ORG_ID);

const container = document.getElementById("root");
const root = createRoot(container);

const renderApp = async () => {
  const IssuerFeatureFlagProvider = await buildLDProvider(
    CONFIG.LAUNCH_DARKLY_CLIENT_ID
  );

  root.render(
    <IssuerFeatureFlagProvider>
      <IssuerColorMode />
      <IssuerApiProvider>
        <IssuerReduxProvider>
          <IssuerQueryProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </IssuerQueryProvider>
        </IssuerReduxProvider>
      </IssuerApiProvider>
    </IssuerFeatureFlagProvider>
  );
};

renderApp();
