import { useForm } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Input } from "@equidefi/ui";
import { PhoneField } from "@equidefi/portals/components/Auth";
import { useUpdateUserById } from "@equidefi/portals/hooks/useUsers";

interface EditContactInfoModalProps {
  modal: {
    isOpen: boolean;
    onClose: () => void;
  };
  user: User;
  onSuccess: () => void;
}

export const EditContactInfoModal = ({ modal, user, onSuccess }: EditContactInfoModalProps) => {
  const toast = useToast();
  const updateUser = useUpdateUserById(user?.id);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      phone: user?.phone,
      address_street_1: user?.address_street_1,
      address_street_2: user?.address_street_2,
      address_city: user?.address_city,
      address_state: user?.address_state,
      address_postal_code: user?.address_postal_code,
      address_country: user?.address_country,
    }
  });

  const onSubmit = async (data) => {
    try {
      await updateUser.mutateAsync(data);
      toast({
        status: "success",
        description: "Contact info updated successfully",
      });
      onSuccess();
      modal.onClose();
    } catch (error) {
      toast({
        status: "error",
        description: error.response?.data?.errors?.join(". ") || "Something went wrong",
      });
    }
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Edit Contact Info</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <PhoneField
                label="Phone"
                error={errors.phone?.message}
                setFieldValue={(_, value) => setValue("phone", value)}
                {...register("phone")}
              />
              <Input
                label="Street Address"
                {...register("address_street_1")}
              />
              <Input
                label="Street Address 2"
                {...register("address_street_2")}
              />
              <Input
                label="City"
                {...register("address_city")}
              />
              <Input
                label="State"
                {...register("address_state")}
              />
              <Input
                label="Postal Code"
                {...register("address_postal_code")}
              />
              <Input
                label="Country"
                {...register("address_country")}
              />
            </ModalBody>
            <ModalFooter as={ButtonGroup}>
              <Button type="submit" isLoading={updateUser.isLoading}>Save</Button>
              <Button variant="outline" onClick={modal.onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}; 