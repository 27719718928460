import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import NotesClient from "../clients/NotesClient";
import { useApi } from "./useApi";

export type TNote = {
  id: string;
  investment_id: string;
  author_id: string;
  author: {
    id: string;
    full_name: string;
  };
  content: string;
  create_date: string;
};

export type TNotes = TNote[];

export const useInvestmentNotes = (id?: string, options = {}) => {
  const notesApi = useApi(NotesClient);

  return useQuery<{ data: TNotes }>({
    queryFn: () => notesApi.all(id as string),
    queryKey: ["investment", id, "notes"],
    enabled: !!id,
    ...options,
  });
};

export const useInvestmentNoteCreate = (id?: string, options = {}) => {
  const queryClient = useQueryClient();
  const notesApi = useApi(NotesClient);

  return useMutation(
    async (data: { content: string }) => {
      if (!id) return null;

      const result = await notesApi.create(id, data.content);

      queryClient.invalidateQueries(["investment", id, "notes"]);

      return result;
    },
    {
      ...options,
    }
  );
};
