import React from "react";
import { Tabs, TabList } from "@chakra-ui/react";
import { Tab } from "@equidefi/ui";

interface TabItem {
  label: string;
  to: string;
}

interface TabNavigationProps {
  currentLabel: string;
  tabs: TabItem[];
}

export function TabNavigation({ currentLabel, tabs }: TabNavigationProps): React.JSX.Element {
  const selectedIndex = tabs.findIndex(tab => tab.label === currentLabel);

  return (
    <Tabs
      mb={2}
      variant="equidefi"
      index={selectedIndex === -1 ? undefined : selectedIndex}
    >
      <TabList>
        {tabs.map((tab) => (
          <Tab
            key={tab.to}
            href={tab.to}
            isSelected={currentLabel === tab.label}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
} 