import { CONFIG } from "@/constants/config";
import { Box } from "@chakra-ui/react";

const EnvironmentMap = {
  local: {
    color: "equidefi.darkGray",
    label: "Local",
  },
  dev: {
    color: "equidefi.green",
    label: "Dev",
  },
  staging: {
    color: "equidefi.yellow",
    label: "Staging",
  },
  demo: {
    color: "equidefi.blue",
    label: "Demo",
  },
};

export function EnvironmentTopStrip() {
  const env = EnvironmentMap[CONFIG.ENV];
  if (!env) return null;

  return <Box w="100vw" bg={env.color} h="6px" />;
}

export function EnvironmentCard() {
  const environment = EnvironmentMap[CONFIG.ENV];
  if (!environment) return null;

  return (
    <Box
      w="calc(100% - 56px)"
      mx="auto"
      py="1"
      borderRadius="md"
      textAlign="center"
      textStyle="h2"
      bg={environment.color}
      textTransform="uppercase"
      maxW="32"
      color="white"
    >
      {environment.label}
    </Box>
  );
}
