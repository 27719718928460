/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Box,
    Button,
    Link as CLink,
    Flex,
    Tooltip,
    useToast,
  } from "@chakra-ui/react";
import { usePDF } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router";

import Spinner from "@/components/spinner";
import { STOCK_TYPES } from "@/constants";
import { CurrencyFormatter, NumberFormatter } from "@/constants/format";
import { isAdmin, isOfferingOwner } from "@/helpers";
import { useOfferingTranche } from "@/hooks/useOfferings";
import Header from "@/layouts/header";
import TrancheTable from "@/pages/offering/components/TrancheTable";
import
  {
    DateTimeService,
    MomentDateTimeAdapter,
  } from "@/services/DateTimeService";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { PDFDocument } from "@equidefi/portals/components/PDF";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import { Heading, Paper, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import { createColumnHelper } from "@tanstack/react-table";
import { ClosingCertificatePdf } from "./pdfs/ClosingCertificate";
import { TailPdf } from "./pdfs/Tail";

const dateFormat = new DateTimeService(new MomentDateTimeAdapter());

const CertificateSummaryDataPoint = ({ label, value }) => {
  return (
    <li className="list-group-item px-4 d-flex justify-content-between w-100 align-items-center">
      <strong style={{ maxWidth: "75%" }}>{label}</strong>
      <span>{value}</span>
    </li>
  );
};

const OfferingTranche = () => {
  const { trauncheId: id, id: offeringId } = useParams();
  const { data: user } = useCurrentUser();
  const { data: tranche, isLoading } = useOfferingTranche(id, offeringId);
  const { offering, issuer, investments, summary } = tranche ?? {};

  const [formDSpinner, showFormDSpinner] = useState(false);
  const [csvSpinner, setCsvSpinner] = useState(false);
  const offeringApi = useApi(OfferingClient);

  const toast = useToast();

  const canDownload = useMemo(
    () => isAdmin(user) || isOfferingOwner(user, offeringId),
    [user, offeringId]
  );

  const disabledDownloadCertificate =
    !investments?.length || !issuer || !canDownload;

  const downloadFile = async (documentType, filename) => {
    const typeMap = {
      csv: "data:text/csv",
      formd: "data:application/pdf;base64",
      certificate: "data:application/pdf;base64",
      tail: "data:application/pdf;base64",
    };

    try {
      const data = await offeringApi.getTrauncheDocument(
        id,
        offeringId,
        documentType
      );

      /** @type {Blob | string} */
      const content =
        documentType === "csv"
          ? new Blob(data.split("\n"), { type: "text/csv;charset=utf-8" })
          : `${typeMap[documentType]},${data.data}`;

      FileSaver.saveAs(content, filename);
    } catch (e) {
      toast({
        status: "error",
        description: `Sorry, we are having trouble downloading ${filename}`,
      });
    }
  };

  const downloadFormD = async () => {
    showFormDSpinner(true);
    await downloadFile("formd", `Offering Form D - ${offering.name}.pdf`);
    showFormDSpinner(false);
  };

  const downloadCSVExport = async () => {
    setCsvSpinner(true);
    await downloadFile(
      "csv",
      `Offering Investors Export - ${offering.name}.csv`
    );
    setCsvSpinner(false);
  };

  /** @type {import("@tanstack/react-table").ColumnHelper<import("@/types/dto").TInvestmentDTO>} */
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "Name",
      enableSorting: false,
      cell: (info) => {
        const { user } = info.row.original;
        return (
          <>
            <Text mb={0}>{userFullName(user)}</Text>
            <Flex color="gray.400" align="center" gap={2}>
              <Icon.Mail size=".85em" />
              <CLink
                href={`mailto:${user.email}`}
                rel="external noreferrer noopener"
                target="_blank"
                textDecor="underline"
              >
                {user.email}
              </CLink>
            </Flex>
          </>
        );
      },
    }),
    columnHelper.accessor("accredited", {
      header: "Accredited",
      enableSorting: false,
      cell: (info) => (info.getValue() ? "Yes" : "No"),
      meta: { align: "center" },
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      enableSorting: false,
      cell: (info) => CurrencyFormatter.format(info.getValue()),
      meta: { align: "center" },
    }),
    columnHelper.accessor("quantity", {
      header: "Quantity",
      enableSorting: false,
      cell: (info) => CurrencyFormatter.format(info.getValue()),
      meta: { align: "center" },
    }),
    columnHelper.accessor("payment_type", {
      header: "Fund Method",
      enableSorting: false,
    }),
    columnHelper.accessor("funding_date", {
      header: "Funding Date",
      enableSorting: false,
      cell: (info) => {
        return dateFormat.formatReadableTimestamp(info.getValue());
      },
    }),
  ];

  const ClosingDocument = useMemo(() => {
    if (!issuer || !canDownload) return <PDFDocument />;

    return (
      <ClosingCertificatePdf
        issuer={issuer}
        offering={offering}
        investments={investments}
        summary={summary}
      />
    );
  }, [issuer, canDownload]);

  const TailDocument = useMemo(() => {
    if (!issuer || !canDownload) return <PDFDocument />;

    return (
      <TailPdf
        issuer={issuer}
        offering={offering}
        quantity={summary.total_security_type_sold}
        investments={investments}
        unitPrice={offering.unit_price}
      />
    );
  }, [issuer, canDownload]);

  const [certificate, updateCertificate] = usePDF({
    document: ClosingDocument,
  });
  const [tail, updateTail] = usePDF({ document: TailDocument });

  useEffect(() => {
    if (ClosingDocument) updateCertificate(ClosingDocument);
    if (TailDocument) updateTail(TailDocument);
  }, [TailDocument, ClosingDocument]);

  if (isLoading) {
    return <Spinner show />;
  }

  return (
    <>
      <Header
        name="Closing Tranche Summary"
        backTo={[
          `/offering/${offeringId}/tranches`,
          "Back to Closing Tranches",
        ]}
        title={offering?.name}
      >
        <Flex gap="2">
          {isAdmin(user) && (
            <Button
              as={Link}
              colorScheme="gray"
              to={`/v2/offerings/${offeringId}/tranches/${id}`}
            >
              V2
            </Button>
          )}
          <Tooltip
            label="You don't have permission to download closing certificate"
            isDisabled={false}
            hasArrow
          >
            <Button
              as={CLink}
              href={canDownload && certificate.url}
              download={`Closing Tranche Summary - ${offering?.name}`}
              isLoading={certificate.loading}
              _hover={{
                textDecor: "none",
              }}
              isDisabled={disabledDownloadCertificate}
            >
              Print Certificate
            </Button>
          </Tooltip>
        </Flex>
      </Header>

      {/* {offering && <PDFViewer height="800px" width="100%"></PDFViewer>} */}

      <Box className="container-fluid">
        <div className="row">
          <h3>Summary</h3>
          <div className="col-12 col-lg-6">
            <div className="card">
              <ul className="list-group list-group-flush">
                <CertificateSummaryDataPoint
                  label="Issuer Name"
                  value={issuer?.name}
                />
                <CertificateSummaryDataPoint
                  label="Launch Date"
                  value={dateFormat.formatMonthDayYear(offering?.launch_date)}
                />
                <CertificateSummaryDataPoint
                  label="Closing Date"
                  value={dateFormat.formatMonthDayYear(summary?.closing_date)}
                />
                <CertificateSummaryDataPoint
                  label="Total Closing Amount"
                  value={CurrencyFormatter.format(
                    summary?.total_closing_amount
                  )}
                />
                <CertificateSummaryDataPoint
                  label={`Total ${
                    STOCK_TYPES[offering?.stock_type] ?? offering?.stock_type
                  } Sold`}
                  value={NumberFormatter.format(
                    summary?.total_security_type_sold ?? 0
                  )}
                />
                <CertificateSummaryDataPoint
                  label="Number of Investors"
                  value={investments?.length ?? 0}
                />
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-header-title">Documents</h3>
              </div>
              <div className="card-body p-0">
                <ul className="list-group list-group-flush">
                  <CertificateSummaryDataPoint
                    label="Transfer Agent Instruction Letter (TAIL)"
                    value={
                      <Button
                        as={CLink}
                        href={canDownload && tail.url}
                        isLoading={tail.loading}
                        download={`Offering TAIL - ${offering?.name}.pdf`}
                        colorScheme="gray"
                        size="xs"
                        _hover={{
                          textDecor: "none",
                        }}
                        isDisabled={!canDownload}
                      >
                        Download
                      </Button>
                    }
                  />
                  <CertificateSummaryDataPoint
                    label="Form D"
                    value={
                      <Button
                        isLoading={formDSpinner}
                        isDisabled={!canDownload}
                        colorScheme="gray"
                        size="xs"
                        onClick={() => downloadFormD()}
                      >
                        Download
                      </Button>
                    }
                  />
                  <CertificateSummaryDataPoint
                    label="Export Investors"
                    value={
                      <Button
                        isLoading={csvSpinner}
                        isDisabled={!canDownload}
                        colorScheme="gray"
                        size="xs"
                        onClick={() => downloadCSVExport()}
                      >
                        Download
                      </Button>
                    }
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ pageBreakAfter: "always" }} />

        <Heading as="h3" size="md">
          Closed Investors
        </Heading>

        <Paper>
          <TrancheTable data={investments} columns={columns} />
        </Paper>
      </Box>
    </>
  );
};

export default OfferingTranche;
