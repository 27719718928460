import { ApiClient } from "./ApiClient";

class NotesClient extends ApiClient {
  async all(investmentId: string) {
    const response = await this.get(`/v2/notes/${investmentId}`);
    return response.data;
  }

  async create(investmentId: string, content: string) {
    const response = await this.post(`/v2/notes/${investmentId}`, { content });

    return response.data;
  }
}

export default NotesClient;
