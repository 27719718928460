import { Container, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router";

import { TabNavigation } from "@/components/TabNavigation";
import { OFFERING_TABS } from "@/constants/tabs";
import { useOfferingContext } from "./OfferingContext";
import { OfferingHeaderCard } from "./components/OfferingHeaderCard";

export default function OfferingAreaContainer(): React.JSX.Element {
  const { offering: offeringData } = useOfferingContext();
  const { offering } = offeringData || {};

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <OfferingHeaderCard offering={offering} />
        <TabNavigation currentLabel="Investments" tabs={OFFERING_TABS} />
        <Outlet />
      </VStack>
    </Container>
  );
} 