import { Stack } from "@chakra-ui/react";
import { Outlet } from "react-router";

import { EnvironmentTopStrip } from "@/components/EnvironmentCard";
import { NavBar } from "@/components/nav";

export const AppLayout = () => {
  return (
    <>
      <EnvironmentTopStrip />
      <Stack
        as="main"
        direction={{ base: "column", lg: "row" }}
        minH={"100vh"}
        h="full"
        bgColor="#e9eef6"
      >
        <NavBar>
          <Outlet />
        </NavBar>
      </Stack>
    </>
  );
};
