import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";

import { ApiClientContext } from "@equidefi/portals/hooks/useApi";
import { EquidefiTheme } from "@equidefi/ui";

import { ApiInstance } from "../clients/api";
import Store from "../store";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const IssuerApiProvider = ({ children }) => {
  return (
    <ApiClientContext.Provider value={ApiInstance}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const IssuerColorMode = () => {
  return (
    <ColorModeScript initialColorMode={EquidefiTheme.config.initialColorMode} />
  );
};

export const IssuerChakraProvider = ({ theme, children }) => {
  return (
    <ChakraProvider
      toastOptions={{
        defaultOptions: {
          isClosable: true,
          duration: 5_000,
          position: "top-right",
          variant: "top-accent",
        },
      }}
      theme={theme}
    >
      {children}
    </ChakraProvider>
  );
};

export const IssuerQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export const IssuerReduxProvider = ({ children }) => {
  return <Provider store={Store}>{children}</Provider>;
};
