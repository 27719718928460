import {
  Card,
  CardBody,
  Flex,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import CopyableText from "@equidefi/portals/components/CopyableText";
import {
  useCurrentUser,
  useGetUserById,
} from "@equidefi/portals/hooks/useUsers";
import { Heading } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import { isAdmin } from "@/helpers";

import { EditBasicInfoModal } from "../modals/EditBasicInfoModal";
import { EditContactInfoModal } from "../modals/EditContactInfoModal";

interface InvestmentSidebarCardsProps {
  investment?: Investment;
}

function InvestmtentUserCard({ user }) {
  return (
    <Card>
      <CardBody>
        <Text textTransform="uppercase" color="gray.500" fontSize="sm">
          User Account
        </Text>

        <Heading textStyle="h2" fontSize="lg" mb={2}>
          {user?.first_name} {user?.last_name}
        </Heading>
        <Flex align="center" gap={2} mb={2}>
          <Icon.Mail size="1.2em" />
          <CopyableText text={user?.email} />
        </Flex>
        <Flex align="center" gap={2}>
          <Icon.Phone size="1.2em" />
          <CopyableText text={user?.phone} />
        </Flex>
      </CardBody>
    </Card>
  );
}

export function InvestmentSidebarCards({
  investment,
}: InvestmentSidebarCardsProps) {
  const { data: currentUser } = useCurrentUser();
  const basicInfoModal = useDisclosure();
  const contactInfoModal = useDisclosure();
  const canEdit = isAdmin(currentUser);

  const { data: user, refetch: refetchUser } = useGetUserById(
    investment?.user_id
  );
  const primaryInvestor = investment?.investors?.[0];

  if (!user) return null;

  return (
    <VStack spacing={4} align="stretch" flex={1}>
      <InvestmtentUserCard user={investment.user} />

      {canEdit && (
        <>
          <EditBasicInfoModal
            modal={basicInfoModal}
            user={user}
            onSuccess={() => {
              refetchUser();
            }}
          />
          <EditContactInfoModal
            modal={contactInfoModal}
            user={user}
            onSuccess={() => {
              refetchUser();
            }}
          />
        </>
      )}
    </VStack>
  );
}
