export const INVESTMENT_TABS = [
  {
    label: "Summary",
    to: "./summary",
  },
  {
    label: "Payment",
    to: "./payments",
  },
  {
    label: "KYC",
    to: "./kyc",
  },
  {
    label: "Agreement",
    to: "./agreements",
  },
  {
    label: "Accreditation",
    to: "./accreditation",
  },
  {
      label: "Activity Log",
      to: "./history",
  },
];

export const OFFERING_TABS = [
  {
    label: "Investments",
    to: "./investments",
  },
  {
    label: "Closing",
    to: "./closing",
  },
  {
    label: "Closed Tranches",
    to: "./tranches",
  },
  {
    label: "Payments",
    to: "./payments",
  },
];
