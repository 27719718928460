/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Button,
    Container,
    Flex,
    GridItem,
    HStack,
    SimpleGrid,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
  } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";
import swal from "sweetalert";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import { Heading, Paper, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import TrauncheInvestmentModal from "../../components/offerings/TrauncheInvestmentModal";
import Spinner from "../../components/spinner";
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import { isAdmin, isIssuerViewer, isOfferingOwner } from "../../helpers";
import { sumProperty } from "../../helpers/tranches";
import
  {
    useOffering,
    useOfferingTrauncheAvailableInvestments,
    useOfferingTraunches,
  } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const OfferingTraunches = () => {
  const { id } = useParams();
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: offering } = useOffering(id);
  const { data: tranches, isLoading, isSuccess } = useOfferingTraunches(id);

  const { data: available } = useOfferingTrauncheAvailableInvestments(id);

  const [currentInvestor, setCurrentInvestor] = useState(null);
  const offeringApi = useApi(OfferingClient);

  useEffect(() => {
    if (isIssuerViewer(user, offering?.issuer_id)) {
      navigate(`/offering/${id}/overview`);
    }
  }, [offering?.issuer_id, user, navigate]);

  const goToTranche = (trancheId) => {
    navigate(`/offering/${id}/tranches/${trancheId}`);
  };

  const handleCreate = async (user) => {
    const status = await swal({
      title: `Close Tranche`,
      text: `Sure you are ready to close this tranche of investors?`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await offeringApi.createTruanche(id);

      queryClient.invalidateQueries(["offerings", id]);

      await swal({
        title: `Done!`,
        text: `You've closed a tranche with ${available.length} investors`,
        icon: "success",
        dangerMode: true,
      });
    } catch (e) {
      swal({
        title: `Sorry!`,
        text: e.response.data?.errors?.join(", "),
        icon: "error",
        dangerMode: true,
      });

      throw e;
    }
  };

  const canEdit = useMemo(
    () => isAdmin(user) || isOfferingOwner(user, id),
    [user, id]
  );

  const anyAvailableInvestors = available?.length > 0;

  if (!offering || isLoading) {
    return <Spinner show />;
  }

  return (
    <>
      <Header name="Closing Tranches" title={offering.name}>
        <Flex gap="2">
          {isAdmin(user) && (
            <Button
              as={Link}
              colorScheme="gray"
              to={`/v2/offerings/${id}/tranches`}
            >
              V2
            </Button>
          )}
        </Flex>
      </Header>
      <Container maxW="full" px={{ base: 4, lg: 8 }}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
          <GridItem>
            <HStack w="full" align="center" justify="space-between" mb={4}>
              <Heading textStyle="h1" mb={0}>
                Available Investments to Close
              </Heading>
              {canEdit && (
                <Tooltip
                  isDisabled={anyAvailableInvestors}
                  hasArrow
                  placement="auto-start"
                  label="There must be at least one countersigned or completed investor to create a tranche"
                >
                  <Button
                    leftIcon={
                      !anyAvailableInvestors && <Icon.Info size="1em" />
                    }
                    size="sm"
                    isDisabled={!anyAvailableInvestors}
                    onClick={handleCreate}
                  >
                    Close Tranche
                  </Button>
                </Tooltip>
              )}
            </HStack>
            {available?.length > 0 && (
              <Paper mb={4}>
                <TableContainer>
                  <Table size="md" variant="simple" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th>Total Investors</Th>
                      <Th textAlign="right">Total Amount</Th>
                      <Th textAlign="center">Total Unit Quantity</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td textAlign="center">{available?.length || 0}</Td>
                      <Td textAlign="right">{CurrencyFormatter.format(sumProperty(available, 'amount'))}</Td>
                      <Td textAlign="center">{NumberFormatter.format(sumProperty(available, 'quantity'))}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
            <Paper>
              <TableContainer>
                <Table size="md" variant="simple" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th>Investor Name</Th>
                      <Th textAlign="right">Amount</Th>
                      <Th textAlign="center">Unit Quantity</Th>
                      <Th textAlign="center">Status</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {available?.map((investment) => (
                      <Tr key={investment.id}>
                        <Td>{userFullName(investment.vault)}</Td>
                        <Td textAlign="right">{CurrencyFormatter.format(investment.amount)}</Td>
                        <Td textAlign="center">{NumberFormatter.format(investment.quantity)}</Td>
                        <Td textAlign="center">
                          <StatusBadge action={investment.status} />
                        </Td>
                        <Td textAlign="center">
                          <Button
                            onClick={() => setCurrentInvestor(investment)}
                            colorScheme="gray"
                            size="xs"
                          >
                            Info
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Paper>
          </GridItem>

          <GridItem>
            <HStack mb={4}>
              <Heading textStyle="h1" mb={0}>
                Closing Totals
              </Heading>
            </HStack>
            {isSuccess && tranches?.length > 0 ? (
              <>
                <Paper mb={4}>
                  <TableContainer>
                  <Table size="md" variant="simple" colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th textAlign="center">Closings</Th>
                        <Th textAlign="right">Amount</Th>
                        <Th textAlign="center">Quantity</Th>
                        <Th textAlign="center">Investors</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td textAlign="center">{tranches?.length || 0}</Td>
                        <Td textAlign="right">{CurrencyFormatter.format(sumProperty(tranches, 'aggregations.total_investment_amount'))}</Td>
                        <Td textAlign="center">{NumberFormatter.format(sumProperty(tranches, 'aggregations.total_investment_quantity'))}</Td>
                        <Td textAlign="center">{NumberFormatter.format(sumProperty(tranches, 'aggregations.total_investments'))}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                </Paper>
                <HStack mb={4}>
                  <Heading textStyle="h1" mb={0}>
                    Closed Tranches
                  </Heading>
                </HStack>
                <Paper>
                  <TableContainer>
                    <Table size="md" variant="simple" colorScheme="gray">
                      <Thead>
                        <Tr>
                          <Th>Closing Date</Th>
                          <Th textAlign="right">Amount</Th>
                          <Th textAlign="center">Quantity</Th>
                          <Th textAlign="center">Investors</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {tranches?.map((tranche) => {
                          return (
                            <Tr
                              onClick={() => goToTranche(tranche.id)}
                              style={{ cursor: "pointer" }}
                              key={tranche.id}
                            >
                              <Td>
                                {moment(tranche.finalized_date).format(
                                  "MMM DD, YYYY"
                                )}
                              </Td>
                              <Td textAlign="right">
                                {CurrencyFormatter.format(
                                  tranche.aggregations.total_investment_amount
                                )}
                              </Td>
                              <Td textAlign="center">
                                {NumberFormatter.format(
                                  tranche.aggregations.total_investment_quantity
                                )}
                              </Td>
                              <Td textAlign="center">
                                {NumberFormatter.format(
                                  tranche.aggregations.total_investments
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Paper>
              </>
            ) : (
              <Paper p="4">
                <Text mb="0">No tranches created</Text>
              </Paper>
            )}
          </GridItem>
        </SimpleGrid>
      </Container>
      <TrauncheInvestmentModal
        investment={currentInvestor}
        onHide={() => setCurrentInvestor(null)}
      />
    </>
  );
};

export default OfferingTraunches;
