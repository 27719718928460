import {
  Box,
  Button,
  CardBody,
  Divider,
  Flex,
  Text,
  useToast,
  VStack,
  Card,
} from "@chakra-ui/react";
import { Heading } from "@equidefi/ui";
import moment from "moment";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { EquidefiMarkdown } from "@equidefi/portals/components/Markdown";
import {
  useInvestmentNoteCreate,
  useInvestmentNotes,
} from "@equidefi/portals/hooks/useNotes";
import { ExpandableInput } from "@equidefi/ui/forms/Input";

export default function InvestmentNotes({ investmentId }: { investmentId: string }) {
  const { data } = useInvestmentNotes(investmentId);
  const createNote = useInvestmentNoteCreate(investmentId);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: {
      content: "",
    },
  });

  const handleOnSubmit = async (data: { content: string }) => {
    await createNote.mutateAsync(data);
    reset();

    try {
      toast({
        status: "success",
        description: "Note added!",
      });
    } catch (e) {
      toast({
        status: "error",
        description: "Sorry. Note could not be saved",
      });
    }
  };

  const notes = useMemo(() => data?.data ?? [], [data]);

  return (
    <Card w="full">
      <CardBody>
        <Heading textStyle="h2" fontSize="xl" fontWeight="medium" mb={4}>
          Notes
        </Heading>
        <VStack spacing={4} align="stretch">
          {notes.map((note) => (
            <Box key={note.id}>
              <Box mb={8}>
                <Flex gap={2} mb={-3}>
                  <Text fontWeight="medium">{note.author.full_name}</Text>
                  <Text color="gray.500" fontSize="sm">
                    ·
                  </Text>
                  <Text color="gray.500" fontSize="sm">
                    on {moment(note.create_date).format("LLL")}
                  </Text>
                </Flex>
                <EquidefiMarkdown>{note.content}</EquidefiMarkdown>
              </Box>
              <Divider border="0" h="1px" mt={-4} mb={0} />
            </Box>
          ))}
          <Box as="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Flex gap={2} mb={2}>
              <ExpandableInput
                label="Note"
                isRequired
                labelProps={{ display: "none" }}
                {...register("content", { required: true })}
                placeholder="Add a note..."
                rows={1}
                value={watch("content")}
              />
              <Button
                isLoading={createNote.isLoading}
                isDisabled={!isValid}
                type="submit"
                mt="1"
                colorScheme="gray"
              >
                Add Note
              </Button>
            </Flex>
            <Text fontSize="sm" color="gray.500">
              Anyone with viewer access to the offering is able to see these
              notes. Investors are not able to see these notes. Notes can not be
              edited or deleted.
            </Text>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
}
