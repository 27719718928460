import React, { useState } from "react";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Select from "react-select";

const FormControl = ({
  title,
  label,
  name,
  value,
  type,
  options,
  required,
  placeholder,
  disabled,
  onChange,
  error,
  minDate,
  maxDate,
  maxLength,
  readOnly,
  showPasswordOverwrite,
  ...extra
}) => {
  const formGroupStyle = disabled ? "form-group text-muted" : "form-group";
  const formControlStyle = disabled
    ? "form-control text-muted mt-2"
    : "form-control mt-2 ";

  const [showPassword, setShowPassword] = useState(false);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div className={formGroupStyle}>
        {title && (
          <label>
            {title} {required && <span className="text-danger">*</span>}
          </label>
        )}

        {["text", "email"].includes(type) && (
          <input
            className={`${formControlStyle} ${readOnly ? "disable" : ""}`}
            type={type}
            name={name}
            placeholder={placeholder}
            autoComplete="off"
            value={value || ""}
            onChange={onChange}
            readOnly={readOnly}
            maxLength={maxLength}
            disabled={disabled}
          />
        )}

        {type === "password" && (
          <div className="input-group input-group-merge">
            <input
              className={disabled ? "form-control text-muted" : "form-control"}
              type={showPasswordOverwrite || showPassword ? "text" : "password"}
              name={name}
              placeholder={placeholder}
              autoComplete="off"
              value={value || ""}
              onChange={onChange}
            />
            {showPasswordOverwrite === undefined && (
              <span className="input-group-text">
                <i
                  className={showPassword ? "fe fe-eye" : "fe fe-eye-off"}
                  onClick={() => setShowPassword((prev) => !prev)}
                ></i>
              </span>
            )}
          </div>
        )}

        {type === "textarea" && (
          <textarea
            className={formControlStyle}
            name={name}
            rows="4"
            value={value || ""}
            onChange={onChange}
          />
        )}

        {type === "phone" && (
          <NumberFormat
            className={`${formControlStyle} ${readOnly ? "disable" : ""}`}
            name={name}
            placeholder={placeholder}
            format="+1 (###) ###-####"
            mask="_"
            value={value || ""}
            readOnly={readOnly}
            onValueChange={({ value }) =>
              onChange({ target: { name: name, value: value } })
            }
          />
        )}

        {type === "ein" && (
          <NumberFormat
            className={`${formControlStyle} ${readOnly ? "disable" : ""}`}
            name={name}
            placeholder={placeholder}
            format="##-#######"
            mask="_"
            value={value || ""}
            readOnly={readOnly}
            onValueChange={({ value }) =>
              onChange({ target: { name: name, value: value } })
            }
          />
        )}

        {type === "postalcode" && (
          <NumberFormat
            name={name}
            className={formControlStyle}
            placeholder={placeholder}
            format="#####"
            mask="_"
            value={value || ""}
            onValueChange={({ value }) =>
              onChange({ target: { name: name, value: value } })
            }
          />
        )}

        {type === "money" && (
          <NumberFormat
            allowNegative={false}
            allowLeadingZeros={true}
            thousandSeparator={true}
            prefix="$"
            {...extra}
            name={name}
            className={formControlStyle}
            placeholder={placeholder}
            value={parseFloat(value) === 0 ? "0" : value || ""}
            disabled={disabled}
            onValueChange={({ value }) =>
              onChange({ target: { name: name, value: parseFloat(value) } })
            }
          />
        )}

        {type === "number" && (
          <NumberFormat
            allowNegative={false}
            thousandSeparator={true}
            {...extra}
            name={name}
            className={formControlStyle}
            placeholder={placeholder}
            value={value || ""}
            disabled={disabled}
            onValueChange={({ value }) =>
              onChange({ target: { name: name, value: value } })
            }
          />
        )}

        {type === "percent" && (
          <NumberFormat
            name={name}
            className={formControlStyle}
            placeholder={placeholder}
            allowNegative={false}
            thousandSeparator={true}
            suffix={"%"}
            value={value || ""}
            disabled={disabled}
            onValueChange={({ value }) =>
              onChange({ target: { name: name, value: value } })
            }
            maxLength={maxLength}
          />
        )}

        {type === "select" && (
          <Select
            className="mt-2"
            name={name}
            options={options}
            value={value || ""}
            onChange={(value) => onChange({ target: { name: name, ...value } })}
            isOptionDisabled={(option) => option.disabled}
            isDisabled={disabled}
          />
        )}

        {type === "date" && (
          <DatePicker
            name={name}
            className={formControlStyle}
            dateFormat="MMMM d, yyyy h:mm aa"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            filterTime={filterPassedTime}
            //minDate={new Date()}
            autoComplete="off"
            selected={value}
            onChange={(value) =>
              onChange({ target: { name: name, value: value } })
            }
          />
        )}

        {type === "checkbox" && (
          <div className="d-flex justify-content-between">
            {label && (
              <label>
                {label} {required && <span className="text-danger">*</span>}
              </label>
            )}
            <div className="form-check form-switch float-right">
              <input
                type="checkbox"
                className="form-check-input"
                name={name}
                value={value}
                defaultChecked={value}
                onChange={onChange}
              />
            </div>
          </div>
        )}

        {error && <div className="text-danger mt-2 ms-1 h5">{error}</div>}
      </div>
    </>
  );
};

export default FormControl;
