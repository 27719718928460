import TrauncheInvestmentModal from "@/components/offerings/TrauncheInvestmentModal";
import
  {
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
  } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import
  {
    flexRender,
    getCoreRowModel,
    useReactTable as useTable,
  } from "@tanstack/react-table";
import { useState } from "react";

const TrancheTable = ({ data, columns }) => {
  const [sorting, setSorting] = useState([]);
  const [currentInvestor, setCurrentInvestor] = useState(null);

  // Create the table instance
  const table = useTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <TableContainer>
        <Table variant="simple" size="md" colorScheme="gray">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const text = flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );
                  return (
                    <Th
                      key={header.id}
                      id={header.id}
                      style={{
                        textAlign:
                          header.column.columnDef.meta?.align || "start",
                      }}
                    >
                      {text}
                      {header.column.getCanSort() && (
                        <IconButton
                          onClick={() => header.column.toggleSorting()}
                          variant="ghost"
                          colorScheme="gray"
                          size="xs"
                          aria-label={`Sort ${text} ${header.column.getIsSorted()}`}
                          icon={
                            <>
                              {header.column.getIsSorted() === "asc" ? (
                                <Icon.ChevronsUp size="1em" />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <Icon.ChevronsDown size="1em" />
                              ) : (
                                <Icon.ChevronsDown opacity={0.2} size="1em" />
                              )}
                            </>
                          }
                        />
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                cursor="pointer"
                onClick={() => setCurrentInvestor(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    verticalAlign="middle"
                    style={{
                      textAlign: cell.column.columnDef.meta?.align || "start",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <TrauncheInvestmentModal
        investment={currentInvestor}
        onHide={() => setCurrentInvestor(null)}
      />
    </>
  );
};

export default TrancheTable;
