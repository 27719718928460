import { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router";

import { useOfferingFull } from "@/hooks/useOfferings";
import { UseQueryResult } from "@tanstack/react-query";

type OfferingContextType = UseQueryResult<unknown, unknown>;

const OfferingContext = createContext<OfferingContextType>({});

export default function OfferingContextProvider(): React.JSX.Element {
  const { offeringId } = useParams();
  const query = useOfferingFull(offeringId);

  return (
    <OfferingContext.Provider value={query}>
      <Outlet />
    </OfferingContext.Provider>
  );
}

export const useOfferingContext = () => {
  const { data: offering, ...rest } = useContext(OfferingContext);
  return { offering, ...rest };
};
