import React from "react";
import {
  Container,
  VStack,
  HStack,
  Card,
  CardBody,
  Skeleton,
} from "@chakra-ui/react";
import { Heading } from "@equidefi/ui";

import { InvestmentHeaderSkeleton } from "./SkeletonParts";
import { TabNavigation } from "@/components/TabNavigation";
import { INVESTMENT_TABS } from "@/constants/tabs";
import { InvestmentSidebarCardSkeletons } from "./InvestmentSidebarCardSkeletons";

export const InvestmentAgreementsSkeleton: React.FC = () => {
  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <InvestmentHeaderSkeleton />
        <TabNavigation currentLabel="Agreement" tabs={INVESTMENT_TABS} />

        <HStack spacing={4} align="stretch" justify="space-between">
          <VStack justify="flex-start" align="start" gap={4} flex={2}>
            <Card w="full">
              <CardBody>
                <Heading textStyle="h2" fontSize="xl" mb={4}>
                  Subscription Agreement
                </Heading>
                <Skeleton height="20px" width="250px" mb={4} />
                <HStack spacing={3} my={3}>
                  {['Countersign', 'Download', 'Void', 'Upload'].map((_, index) => (
                    <Skeleton key={index} height="40px" width="150px" borderRadius="md" />
                  ))}
                </HStack>
              </CardBody>
            </Card>

            <Card w="full">
              <CardBody>
                <Heading textStyle="h2" fontSize="xl" mb={4}>
                  Investment Signers
                </Heading>
                <Skeleton height="20px" width="300px" mb={6} />
                
                <VStack spacing={3} align="stretch">
                  {[1, 2, 3].map((_, index) => (
                    <Skeleton key={index} height="60px" width="full" borderRadius="md" />
                  ))}
                </VStack>

                <Heading textStyle="h2" fontSize="xl" mt={6} mb={4}>
                  Countersigner
                </Heading>
                
                <Skeleton height="60px" width="full" borderRadius="md" />
              </CardBody>
            </Card>
          </VStack>

          <InvestmentSidebarCardSkeletons />
        </HStack>
      </VStack>
    </Container>
  );
}; 