import {
  Box,
  Card,
  CardBody,
  Flex,
  Image,
  Text,
  IconButton,
  Heading,
  Badge,
  Link,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

interface OfferingHeaderCardProps {
  offering: any;
}

export function OfferingHeaderCard({
  offering,
}: OfferingHeaderCardProps): React.JSX.Element {
  const { issuer, name, exemption_type } = offering || {};
  const { name: issuerName, photo_url: issuerPhotoUrl } = issuer || {};

  return (
    <Card>
      <CardBody>
        <Flex justify="space-between" align="center">
          <Flex align="center" gap={2}>
            <Image
              src={issuerPhotoUrl}
              alt={`${issuerName} Logo`}
              boxSize="64px"
              objectFit="contain"
            />
            <Box mt={1} mb={-4}>
              <Flex align="center" gap={1} mb={0}>
                <Text color="gray.600" fontSize="md" lineHeight="1">
                  {issuerName}
                </Text>
                <IconButton 
                  as={Link}
                  href={`/v2/offerings/admin`}
                  icon={<Icon.ChevronRight size="1em" />} 
                  aria-label="chevron-right" 
                  variant="none" 
                  height="auto"
                  minW="auto"
                  p={0}
                  mt={-4}
                  display="flex"
                  alignItems="center"
                />
              </Flex>
              <Flex align="center" gap={3}>
                <Heading as="h1" fontSize="2xl" lineHeight="1" mt={-2}>
                  {name}
                </Heading>
                <Badge colorScheme="green" mt={-5}>{exemption_type}</Badge>
              </Flex>
            </Box>
          </Flex>
          <Flex align="center" gap={1}>
            <IconButton icon={<Icon.Settings size="1.6em" />} aria-label="settings" variant="none" />
            <Link href="/v2/offerings/edit" fontSize="sm">Offering Settings</Link>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
