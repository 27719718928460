import { Container, Grid, GridItem, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router";

import { useInvestmentContext } from "./InvestmentContext";
import { InvestmentHeaderCard } from "./shared/InvestmentHeaderCard";
import { InvestmentSidebarCards } from "./shared/InvestmentSidebarCards";
import { InvestmentAgreementsSkeleton } from "./skeletons/InvestmentAgreementsSkeleton";

import { TabNavigation } from "@/components/TabNavigation";
import { INVESTMENT_TABS } from "@/constants/tabs";

import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";

export default function InvestmentAreaContainer(): React.JSX.Element {
  const { investment } = useInvestmentContext();
  const { isLoading } = useInvestmentSignatures(investment?.id, {
    refetchInterval: closed ? 2_500 : false,
  });

  if (isLoading) {
    return <InvestmentAgreementsSkeleton />;
  }

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <InvestmentHeaderCard investment={investment} />
        <TabNavigation currentLabel="Agreement" tabs={INVESTMENT_TABS} />

        <Grid gap="4" templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Outlet />
          </GridItem>

          <GridItem>
            <InvestmentSidebarCards investment={investment} />
          </GridItem>
        </Grid>
      </VStack>
    </Container>
  );
}
