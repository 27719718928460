import {
  Box,
  Card,
  CardBody,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

interface InvestmentHeaderCardProps {
  investment: any;
}

export function InvestmentHeaderCard({
  investment,
}: InvestmentHeaderCardProps): React.JSX.Element {
  const { offering, issuer, user } = investment || {};
  const { name: issuerName, photo_url: issuerPhotoUrl } = issuer || {};

  return (
    <Card>
      <CardBody>
        <Flex align="center" gap={3}>
          <Image
            src={issuerPhotoUrl}
            alt={`${issuerName} Logo`}
            boxSize="64px"
            objectFit="contain"
          />
          <Box>
            <Flex align="center" gap={2}>
              <Text color="gray.600" mb={0}>{issuerName}</Text>
              <Icon.ChevronRight size="1em" />
              <Text color="gray.600" mb={0}>{offering?.name}</Text>
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mb={0}>
              Investment by {user?.full_name}
            </Text>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
}
