/* eslint-disable react-hooks/exhaustive-deps */
import
  {
    Button,
    ButtonGroup,
    IconButton,
    Link, useToast
  } from "@chakra-ui/react";
import moment from "moment";
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router";
import swal from "sweetalert";

import AWSClient from "@equidefi/portals/clients/AWSClient";
import { usePresignedPublicUrl } from "@equidefi/portals/hooks/useUpload";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { ALL_CONTENT_TYPES } from "@equidefi/shared/constants/files";
import { Icon } from "@equidefi/ui/icon";

import Spinner from "../../components/spinner";
import { documentCategories } from "../../constants";
import
  {
    createOfferingDocument,
    deleteOfferingDocument,
    isAdmin,
    isOfferingEditor,
    isOfferingOwner,
  } from "../../helpers";
import { useOffering } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const Documents = () => {
  const toast = useToast();
  const { id } = useParams();
  const { data: offering, isLoading, refetch } = useOffering(id);
  const { data: me } = useCurrentUser();
  const presignedPublicUrl = usePresignedPublicUrl();

  const [isValid, setIsValid] = useState(false);
  const [modal, showModal] = useState(false);
  const [category, setCategory] = useState("");
  const [_spinner, showSpinner] = useState(false);
  const [dropdownOther, setDropdownOther] = useState("");

  const spinner = useMemo(() => isLoading || _spinner, [isLoading, _spinner]);

  const handleDelete = async (document) => {
    const status = await swal({
      title: `Remove Document`,
      text: `Are you sure you want to remove '${document.type}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (!status) return;

    try {
      showSpinner(true);
      await deleteOfferingDocument(id, document.id);
      toast({
        status: "success",
        description: `${document.type} has been removed.`,
      });
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: `${document.type} could not be removed.`,
      });
    } finally {
      await refetch();
      showSpinner(false);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value.trim();
    if (value) {
      setDropdownOther(e.target.value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      showSpinner(true);

      const file = acceptedFiles[0];
      const filename = file.name.split(" ").join("_");
      const extension = filename.split(".").pop().toLowerCase();

      const response = await presignedPublicUrl.mutateAsync({
        filename: filename,
        extension: extension,
      });
      const client = new AWSClient();

      try {
        await client.s3Upload(
          response.signedUrl,
          file,
          ALL_CONTENT_TYPES[extension],
          file.size
        );

        let document = {
          name: category,
          type: category !== "Other" ? category : dropdownOther,
          document_url: response.url,
        };

        await createOfferingDocument(id, document);
        setCategory("");
        await refetch();
        showModal(false);
      } catch (e) {
        console.log(e);
        toast({
          status: "error",
          description: "Your file failed to upload!",
        });
      } finally {
        showSpinner(false);
      }
    },
  });

  const canEdit = useMemo(
    () =>
      isAdmin(me) ||
      (isOfferingOwner(me, offering?.id) &&
        ["In Review", "Draft"].includes(offering?.status)),
    [me, offering?.id, offering?.status]
  );

  return (
    <>
      <Header
        id={id}
        name="Investor Package"
        iconName="fe fe-info ms-2"
        title={offering?.name}
        tooltipContent="Please use the upload button to upload PDF files of your offering documents for investor review. In the next step, you can upload only the documents investors will execute."
      >
        {canEdit && (
          <Button size="sm" onClick={() => showModal(true)}>Upload Documents</Button>
        )}
      </Header>
      <Spinner show={spinner}>
        <div className="container-fluid">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-nowrap table-hover card-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Uploaded On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {offering?.documents?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-start align-baseline">
                          {item.type}
                        </td>
                        <td className="align-baseline">
                          {item.create_date &&
                            moment(item.create_date).format("ll")}
                        </td>
                        <td className="text-end align-baseline">
                          <ButtonGroup size="sm" colorScheme="gray">
                            <IconButton
                              as={Link}
                              icon={<Icon.Download size="1em" />}
                              _hover={{ textDecoration: "none" }}
                              href={item.document_url}
                              aria-label="Download"
                              target="_blank"
                              rel="noreferrer"
                            />
                            {canEdit && (
                              <IconButton
                                aria-label="Delete Document"
                                icon={<Icon.Trash size="1em" />}
                                onClick={() => handleDelete(item)}
                              />
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <Modal show={modal} onHide={() => showModal(false)}>
            <Modal.Body>
              <h2 className="mb-0">Upload Documents</h2>
              <hr />
              <Spinner show={spinner}>
                <div className="form-group">
                  <label className="mb-2">
                    Category<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    as="select"
                    name="document.primary"
                    defaultValue={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option hidden value="" disabled>
                      Select Category
                    </option>
                    {documentCategories.map((type, index) => {
                      return (
                        <option key={index} value={type.name}>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {category && category !== "Other" && (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop the respective files here, or click to
                      select files
                    </p>
                  </div>
                )}
                {category === "Other" && (
                  <>
                    <input
                      type="text"
                      name="otherCategory"
                      placeholder="Please specify category"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                    />
                    {isValid && (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop the respective files here, or click to
                          select files
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Spinner>
            </Modal.Body>
          </Modal>
        </div>
      </Spinner>
    </>
  );
};

export default Documents;
