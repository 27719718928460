import { Box, Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Icon } from "../icon";

type PaginationProps = {
  totalPages: number;
  onGoToPage?: (page?: number | string) => void;
  pagesToShow?: number;
  currentPage?: number;
  size?: string;
};

export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  pagesToShow = 3,
  onGoToPage = () => true,
  currentPage = 0,
  size = "md",
}) => {
  const pagesToShowMid = Math.floor(pagesToShow / 2);
  const firstPage =
    currentPage > pagesToShowMid
      ? Math.min(
          currentPage - pagesToShowMid,
          totalPages - pagesToShow + 1 || 1
        )
      : 1;
  const lastPage =
    firstPage + pagesToShow - 1 <= totalPages
      ? firstPage + pagesToShow - 1
      : totalPages;
  const show3Dots = totalPages - lastPage >= 2;
  const showTotalPages = show3Dots || lastPage + 1 === totalPages;

  const isPrevDisabled = currentPage <= 1 || totalPages === 1;
  const isNextDisabled = currentPage >= totalPages || totalPages === 1;

  const pages = useMemo(() => {
    let pagesArray: (string | number)[] = Array.from(
      { length: totalPages },
      (_, i) => i + 1
    );
    pagesArray = pagesArray.slice(firstPage - 1, lastPage);
    pagesArray = show3Dots ? [...pagesArray, "..."] : pagesArray;
    pagesArray = showTotalPages ? [...pagesArray, totalPages] : pagesArray;

    return pagesArray;
  }, [totalPages, firstPage, lastPage, show3Dots, showTotalPages]);

  return (
    <Box as="nav" role="navigation" aria-label="Pagination Navigation">
      <ButtonGroup colorScheme="gray" size={size} isAttached>
        <IconButton
          isDisabled={isPrevDisabled}
          onClick={() => onGoToPage(1)}
          aria-label="Go to the first page"
          icon={<Icon.ChevronsLeft size="1em" />}
        />

        <IconButton
          isDisabled={isPrevDisabled}
          onClick={() => onGoToPage(currentPage - 1)}
          size={size}
          aria-label="Go to the previous page"
          icon={<Icon.ChevronLeft size="1em" />}
        />

        {pages.map((page) =>
          page === "..." ? (
            <Button
              // Don't have the 3 dots do anything.
              pointerEvents="none"
              aria-hidden="true"
              key={page}
            >
              {page}
            </Button>
          ) : (
            <Button
              onClick={() => onGoToPage(page)}
              aria-label={`Go to page ${page}`}
              isActive={currentPage === page}
              key={page}
              {...(currentPage === page ? { "aria-current": true } : {})}
            >
              {page}
            </Button>
          )
        )}

        <IconButton
          isDisabled={isNextDisabled}
          onClick={() => onGoToPage(currentPage + 1)}
          aria-label="Go to the next page"
          icon={<Icon.ChevronRight size="1em" />}
        />

        <IconButton
          isDisabled={isNextDisabled}
          onClick={() => onGoToPage(totalPages)}
          aria-label="Go to the last page"
          icon={<Icon.ChevronsRight size="1em" />}
        />
      </ButtonGroup>
    </Box>
  );
};
